/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect, useState } from "react";
import SidebarComponent from "../../components/navigation/Sidebar";
import Loader from "../../components/Loader";
import { AuthContext } from "../../context/authContext";
import PoolsTable from "../../components/tables/PoolsTable";
import client from "../../utils/client";

const Pools = () => {
  const { user } = useContext(AuthContext);
  const [pools, setPools] = useState([]);
  const [filteredPools, setFilteredPools] = useState([]);
  const [category, setCategory] = useState("All");
  const [searchTerm, setSearchTerm] = useState("");
  // const [filteredData, setFilteredData] = useState([]);

  useEffect(() => {
    if (pools.length < 1) {
      client({
        url: `/txns/pools`,
        method: "GET",
      })
        .then((res) => {
          setPools(res.data.data);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }, []);

  useEffect(() => {
    if (category !== "All" && !searchTerm && !pools.length) {
      client({
        url: `/txns/pools`,
        method: "GET",
      })
        .then((res) => {
          const filteredStrategies = res.data.data.filter(
            (strategy) => strategy.category === category && strategy
          );
          setPools(filteredStrategies);
        })
        .catch((err) => {
          console.log(err);
        });
    }
    if (category === "All" && !searchTerm && !pools.length) {
      client({
        url: `/txns/pools`,
        method: "GET",
      })
        .then((res) => {
          setPools(res.data.data);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }, [category]);

  const categories = [
    "All",
    "Defi",
    "Market Yield",
    "Hedging",
    "Arbitrage",
    "Rebalancing",
  ];

  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
  };
  useEffect(() => {
    const filtered = pools.filter((item) =>
      item.name.toLowerCase().includes(searchTerm.toLowerCase())
    );
    setPools(filtered);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchTerm]);
  return (
    <div>
      <div>
        <Loader />
      </div>
      <div>
        <SidebarComponent user={user} />
      </div>
      <div className="p-4 lg:ml-64 bg-[#0a0c0f] min-h-screen">
        <div className="p-4 border border-gray-600 min-h-screen border-dashed rounded-lg dark:border-gray-700 mt-20">
          <div>
            <div className="max-lg:mb-3">
              <div className="py-5">
                <h1 className="text-gray-300 font-medium text-3xl">
                  All strategies
                </h1>
                <p className="text-gray-500">
                  Deposit into a range of strategies to build your diversified
                  portfolio.
                </p>
              </div>
              <div className="md:flex items-center justify-between w-full max-lg:mb-3 md:space-x-8">
                <div className="md:w-[50%]">
                  <p className="text-gray-400 mb-2">Select a category: </p>
                  <select
                    className="bg-[#181b20] w-full py-2 px-5 text-gray-400 outline-none rounded-md"
                    onChange={(e) => {
                      setCategory(e.target.value);
                    }}
                  >
                    {categories.map((strategyCategory) => {
                      return <option>{strategyCategory}</option>;
                    })}
                  </select>
                </div>
                <div className="md:w-[50%] max-md:mt-4">
                  <p className="text-gray-400 mb-2">Search by name: </p>
                  <input
                    placeholder="Enter strategy name"
                    className="bg-[#181b20] w-full py-2 px-5 text-gray-400 rounded-md"
                    onChange={handleSearchChange}
                  />
                </div>
              </div>
            </div>
            <div className="my-10">
              <PoolsTable pools={pools} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Pools;
